/* Project.css */

.project-card {
    display: flex;         
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}


.project-card img {
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    object-fit: cover;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: white;
}

.overlay h3 {
    margin-bottom: 20px;
}

.overlay p {
    margin-bottom: 30px;
}

.links a {
    color: white;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.links a:hover {
    color: #007EA7;
}

.image-container:hover .overlay {
    opacity: 1;
}

.project-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: cover;
    border-radius: 5px;
}

