.footer {
    padding: 20px;
    background-color: #003459;
    text-align: center;
}

.footer a {
    margin: 0 10px;
    color: #FFFFFF;
    text-decoration: none;
}