.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 10px auto; /* Reduced margin */
  padding: 20px 0; /* Reduced padding */
}

.about-section.reverse {
  flex-direction: row-reverse;
}

.about-section img {
  width: 60%; /* Increased width */
  max-width: 600px; /* Increased max-width */
  min-width: 200px; /* Increased min-width */
  height: auto;
  border-radius: 15%;
  object-fit: cover;
  margin: 20px;
}

.about-section .content {
  width: 40%; /* Adjusted content width */
  font-size: 18px;
  line-height: 1.6;
  color: #206ba0;
}

.about-section .content .title-background h2 {
  font-size: 24px;
  font-family: 'Bricolage Grotesque', sans-serif !important;  /* Although using !important is generally discouraged, sometimes it's necessary */
  color: #185d8e;
  margin-bottom: 20px;
  padding: 5px;
  display: inline-block;
}

.about-section .content p {
  margin-top: 20px;
}

/* Adding some responsiveness */
@media screen and (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .about-section.reverse img {
    order: 2; /* Ensures the reverse images are positioned correctly */
  }

  .about-section img, .about-section .content {
    width: 100%;
  }
}

.title-background {
  position: relative;
  background: linear-gradient(45deg, #003459, #007EA7, #FF5E00, #E5097F, #00D4DB, #3A52D6, #9E20C8, #F1C232, #00A572, #F22F46);
  background-size: 500% 500%;
  -webkit-animation: Gradient 15s infinite;
  -moz-animation: Gradient 15s infinite;
  animation: Gradient 15s infinite;
  padding: 5px;
  display: inline-block;
  border-radius: 10px;
  z-index: 1; /* Ensure this remains below the pseudo-element */
  overflow: hidden; /* Clip pseudo-element where it extends beyond the boundary of the title background */
}

.title-background::after {
  content: '';
  position: absolute;
  top: -20%; /* Expanded the boundaries */
  right: -20%; /* Expanded the boundaries */
  bottom: -20%; /* Expanded the boundaries */
  left: -20%; /* Expanded the boundaries */
  background: radial-gradient(ellipse at center, transparent 55%, white 80%); 
  z-index: 2;
}

/* Animation for gradient background */
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}