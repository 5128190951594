.resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.resume-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.resume-header h1 {
    color: #003459;
    margin-bottom: 10px;
}

.resume-download {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #FFFFFF;
    background-color: #003459;
    text-decoration: none;
    border-radius: 5px;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    list-style: none;
    padding: 0;
    width: 100%;
}

.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.skill p {
    margin-top: 10px;
    color: #003459;
}

.logo {
    width: 3em; 
    height: 3em;
}

