@import 'normalize.css/normalize.css';

body {
  font-family: 'Roboto', sans-serif;
  color: #00171F;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
}

html {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
}

h1, h2, h3, h4, h5, h6 {
  color: #003459;
  font-family: 'Montserrat', sans-serif;
}

.app {
  /* display: flex; */
  /* flex-direction: column; */
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
